import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7475e596"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-wrapper" }
const _hoisted_2 = { class: "modal-container" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XIcon = _resolveComponent("XIcon")!
  const _component_modal = _resolveComponent("modal")!

  return (_ctx.showModal)
    ? (_openBlock(), _createBlock(_component_modal, {
        key: 0,
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModal = false))
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, { name: "modal" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "modal-mask",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
              }, [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                      innerHTML: _ctx.errorList?.map((i) => (i.replace(/,/g, '') + '<br/>') )
                    }, null, 8 /* PROPS */, _hoisted_3),
                    _createVNode(_component_XIcon, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = false)),
                      class: "-ml-1 mr-2 h-5 w-5 text-white hover:cursor-pointer modalIcon",
                      "aria-hidden": "true"
                    })
                  ])
                ])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}