interface Coords {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
}

export default (el: HTMLElement, binding) => {
    let tooltipEl = el.getElementsByClassName('remax-tooltip')[0] as HTMLElement;
    const tooltipCreated = tooltipEl?.className === 'remax-tooltip';

    const position: string = binding.arg || 'bottom';

    if (binding.value) {
        if (!tooltipCreated) {
            tooltipEl = document.createElement('div');
            tooltipEl.classList.add('remax-tooltip');
            el.classList.add('with-tooltip');
            el.appendChild(tooltipEl);
        }
        tooltipEl.style.visibility = 'visible';
    } else if (!binding.value && tooltipCreated) {
        tooltipEl.style.visibility = 'hidden';
    }
    if (!binding.value) {
        return;
    }

    tooltipEl.textContent = binding.value;

    if (!tooltipCreated) {
        el.addEventListener('mouseenter', () => updateTooltipCoords(el, tooltipEl, position), true);
    }
};

function updateTooltipCoords(el: HTMLElement, tooltipEl: HTMLElement, position: string) {
    const parentBox = el.getBoundingClientRect();
    const tooltipBox = tooltipEl.getBoundingClientRect();
    const coords = getPositionCoords(parentBox, tooltipBox, position);
    setTooltipCoords(tooltipEl, coords);
}

function getPositionCoords(parentBox: DOMRect, tooltipBox: DOMRect, position: string): Coords {
    let coords: Coords;
    if (position === 'right') {
        coords = {
            top: `${parentBox.top + parentBox.height * 0.5 - tooltipBox.height * 0.5}px`,
            left: `${parentBox.right + 10}px`,
        };
    } else if (position === 'left') {
        const app = document.getElementById('app');
        coords = {
            top: `${parentBox.top + parentBox.height * 0.5 - tooltipBox.height * 0.5}px`,
            right: `${app.offsetWidth - parentBox.left + 10}px`,
        };
    } else if (position === 'top') {
        const app = document.getElementById('app');
        const main = document.getElementById('main');
        const centre = parentBox.left + parentBox.width * 0.5;
        let left = centre - tooltipBox.width * 0.5;
        if (left + tooltipBox.width > app.offsetWidth) left = app.offsetWidth - tooltipBox.width;
        else if (left < main.offsetLeft) left = main.offsetLeft;
        coords = {
            top: `${parentBox.top - tooltipBox.height - 10}px`,
            left: `${left}px`,
        };
    } else {
        const app = document.getElementById('app');
        const main = document.getElementById('main');
        const centre = parentBox.left + parentBox.width * 0.5;
        let left = centre - tooltipBox.width * 0.5;
        if (left + tooltipBox.width > app.offsetWidth) left = app.offsetWidth - tooltipBox.width;
        else if (left < main.offsetLeft) left = main.offsetLeft;
        coords = {
            top: `${parentBox.bottom + 10}px`,
            left: `${left}px`,
        };
    }
    return coords;
}

function setTooltipCoords(tooltipEl: HTMLElement, coords: Coords) {
    if (coords.top) tooltipEl.style.top = coords.top;
    if (coords.right) tooltipEl.style.right = coords.right;
    if (coords.bottom) tooltipEl.style.bottom = coords.bottom;
    if (coords.left) tooltipEl.style.left = coords.left;
}
