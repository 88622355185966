import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';
import moment from 'moment';
export class EbillingService extends BaseService {
    static get entity() {
        return 'ebilling';
    }
    //
    static async getAllEbillingFees(params) {
        try {
            let page = params?.page || 1;
            let size = params?.size || 35;
            let sortBy = params?.sortBy || 'name';
            let sortOrder = params?.sortOrder || 'asc';
            let filterStartDate = params?.startDate ? moment(params.startDate).format('YYYY-MM-DD') : undefined;
            let filterEndDate = params?.endDate ? moment(params.endDate).format('YYYY-MM-DD') : undefined;
            let filterOperator = params?.filterOperator || 'and';

            let queryParams = `page=${page}&size=${size}&sortBy=${sortBy}&sortOrder=${sortOrder}&filterOperator=${filterOperator}`;
            if (filterStartDate) {
                queryParams += `&filter=startDate:gte:${filterStartDate}`;
            }
            if (filterEndDate) {
                queryParams += `&filter=startDate:lte:${filterEndDate}`;
            }

            let response = await this.get(`ebilling#/fee/find?${queryParams}`);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.message;
            throw new ErrorWrapper(error, message);
        }
    }
    //@Get('findOne/:id')
    static async getEbillingFee(id) {
        try {
            let response = await this.get(`ebilling#/fee/findOne/${id}`);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }
    //@Post('createOne')
    static async createNewEbillingFee(params) {
        try {
            // let body = {
            //     payload: params.fee,
            //     regionId: params.regionId,
            //     officeId: params.officeId,
            // };
            let response = await this.request().post(`ebilling#fee/createOne`, params);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
    //@Put('updateOne/:id')
    static async updateEbillingFee(params) {
        try {
            // let body = {
            //     payload: params.fee,
            //     regionId: params.regionId,
            //     officeId: params.officeId,
            // };
            let response = await this.request().put(`ebilling#fee/updateOne/${params.id}`, params.payload);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
    //@Delete('delete/:id')
    static async removeEbillingFee(id) {
        try {
            let response = await this.delete(`ebilling#/fee/delete/${id}`);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }

    //Billing Report
    //
    static async getAllRegionReport(params) {
        try {
            let response = await this.get(`ebilling#/report/regions?year=${params.year}&month=${params.month}`);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }
    static async getAllRegionReportInRange(params) {
        try {
            let response = await this.get(
                `ebilling#/report/regionsRange?startDate=${params.startDate}&endDate=${params.endDate}`
            );
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }
    static async getPendingApprovals(params) {
        try {
            let response = await this.get(`ebilling#/report/pendingReportApprovals`);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }
    static async updateRegionReportStatus(params) {
        try {
            let response = await this.put(
                `ebilling#/report/updateRegionBillingStatus?month=${params.month}&year=${params.year}&regionId=${params.regionId}&newStatus=${params.Reportstatus}&personId=${params.personId}`
            );
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }
    //
    //billing/regionReport?regionId=5&year=2023&month=3
    static async getRegionReport(params) {
        try {
            let response = await this.get(
                `ebilling#/report/region?regionId=${params.regionId}&year=${params.year}&month=${params.month}`
            );
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getRegionReportWithExeptions(params) {
        try {
            let response = await this.get(
                `ebilling#/report/region/billing-rules-overview?regionId=${params.regionId}&year=${params.year}&month=${params.month}`
            );
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getRegionMonthlyOverview(params) {
        try {
            let response = await this.get(`ebilling#/report/region/annual?regionId=${params.regionId}&year=${params.year}`);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }
    //
    // /transaction/upload
    static async transactionUpload(params) {
        try {
            let response = await this.request().post(`ebilling#transaction/upload`, params.formData);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
    // /report/updateOne
    static async monthlyReportingUpdateOne(params) {
        try {
            let comment = params.comment || '';
            let data = params.data;
            let response = await this.request().put(`ebilling#report/updateOne/${params.id}`, {
                comment: comment,
                payload: {
                    ...data,
                },
            });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
    //
    static async monthlyReportingUpdateOneChild(params) {
        try {
            let comment = params.comment || '';
            let data = params.data;
            let response = await this.request().post(`ebilling#reportDetails/createOne/`, {
                ...data,
            });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
    // /delay/offices
    static async monthlyReportingSetDelayed(params) {
        try {
            let response = await this.request().post(`ebilling#delay/offices`, params);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
    //
    static async regionReportingSetDelayed(params) {
        try {
            let response = await this.request().post(`ebilling#delay/region`, params);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async exportAllRegionBillingReport(params) {
        try {
            // .../report/allRegionsExcelDownload?year=2024&month=7
            let response = await this.request().get(
                `ebilling#report/allRegionsExcelDownload?year=${params.year}&month=${params.month}`,
                { responseType: 'blob' }
            );
            if (response.data) {
                // filename is in the content-disposition header, and looks like this:
                // "attachment; filename=AllRegionsReport-2025-1_2025-02-26T06-44-48.021Z.xlsx"
                const contentDispositionHeader = response.headers['content-disposition'];
                const filenameMatch = contentDispositionHeader.match(/filename=(.+)/);
                const filename = filenameMatch ? filenameMatch[1] : 'AllRegionsReport.xlsx';

                // Create a Blob URL and download the file
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('No file data received in the response.');
            }

            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
    // /report/regionExcelDownload?year=2024&month=7&regionId=29
    static async exportRegionBillingReport(params) {
        try {
            let response = await this.request().get(
                `ebilling#report/regionExcelDownload?year=${params.year}&month=${params.month}&regionId=${params.regionId}`,
                { responseType: 'blob' }
            );
            if (response.data) {
                // Generate filename
                // filename is in the content-disposition header, and looks like this:
                // "attachment; filename=AllRegionsReport-2025-1_2025-02-26T06-44-48.021Z.xlsx"
                const contentDispositionHeader = response.headers['content-disposition'];
                const filenameMatch = contentDispositionHeader.match(/filename=(.+)/);
                const filename = filenameMatch ? filenameMatch[1] : 'RegionsReport.xlsx';

                // Create a Blob URL and download the file
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('No file data received in the response.');
            }

            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    // report/validateBillingPeriod/?regionId=1&month=10&year=2024"
    static async validateBillingPeriod(params) {
        try {
            let response = await this.request().get(
                `ebilling#report/validateBillingPeriod/?regionId=${params.regionId}&month=${params.month}&year=${params.year}`,
                params
            );
            //testing let response = await this.request().get(`ebilling#report/validateBillingPeriod/?regionId=1&month=10&year=2024`, params);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getFees(params) {
        try {
            let response = await this.request().get(
                `ebilling#billing/officeReport?officeId=${params.officeId}&month=${params.month}&year=${params.year}`
            );
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = `No documents found for ${params.officeName}`;
            throw new ErrorWrapper(error, message);
        }
    }

    static async uploadBillingDocuments(id, files) {
        try {
            // Create a FormData object
            const formData = new FormData();

            // Append each file to the FormData object
            for (let i = 0; i < files.length; i++) {
                formData.append('file', files[i]);
            }

            // Send the POST request with FormData
            const response = await this.request().post(`ebilling#report/${id}/documents`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Return a ResponseWrapper instance
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            // Extract error message
            const message = error.response?.data?.message || error.response?.statusText || 'An error occurred';
            throw new ErrorWrapper(error, message);
        }
    }

    static async downloadBillingDocuments(id, officeName) {
        try {
            let response = await this.request().get(`ebilling#report/${id}/documents/download`, { responseType: 'blob' });
            if (response.data) {
                const filename = `${officeName}_documents.zip`;

                // Create a Blob URL and download the file
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('No file data received in the response.');
            }
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = `No documents found for ${officeName}`;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getBillingDocuments(id) {
        try {
            const response = await this.request().get(`ebilling#report/${id}/documents`);
            console.log(response);
            return response.data.data;
        } catch (error) {
            const message = `Could not get documents.`;
            throw new ErrorWrapper(error, message);
        }
    }

    static async downloadBillingDocument(id, documnetId, documentName) {
        try {
            let response = await this.request().get(`ebilling#report/${id}/documents/download/${documnetId}`, {
                responseType: 'blob',
            });
            if (response.data) {
                // Create a Blob URL and download the file
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('No file data received in the response.');
            }
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = `No document found for ${documentName}`;
            throw new ErrorWrapper(error, message);
        }
    }

    static async deleteBillingDocument(id, documnetId) {
        try {
            const response = await this.request().delete(`ebilling#report/${id}/documents/${documnetId}`);
            console.log(response);
            return response.data.data;
        } catch (error) {
            const message = `Could not get documents.`;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getAllOfficesInRegion(id) {
        try {
            let response = await this.get(`ebilling#/office/getOfficesByRegionId/${id}`);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }

    static async ApplySpecialFeeToOffices(feeId, officeIds, billingMonth, billingYear) {
        try {
            let response = await this.request().post(`ebilling#/fee/apply`, {
                feeId: feeId,
                officeIdArray: officeIds,
                billingMonth: billingMonth,
                billingYear: billingYear,
            });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
}
