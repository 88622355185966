import { watch } from 'vue';
import store from '@/store';

export const MetaDataRoutes = {
  META_STRING: '',

  VIEW_OR_EDIT: null,

  MONTHS: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],

  REQUIRED_ROUTES: [
    'Office Dashboard',
    'Office Table',
    'EditOffice',
    'ViewOffice',
    'Create Office',

    'EditPerson',
    'ViewPerson',
    'Persons Table',
    'CreatePerson',

    'MonthlyReporting-Regions-Read-Months-Read-Offices',
    'MonthlyReportingRegionsReadMonths',
    'MonthlyReporting-Regions-Read-Months-Read-Offices-Edit',

    'EditTeam',
    'ViewTeam',
    'Teams Table',
    'CreateTeam',

    'AnnualDuesReportsOverview',
    'AnnualDuesReportsCurrent',
    'AnnualDuesReportsMonthlyOverview',
    'AnnualDuesReportsFinancialOverview',

    'EditFeeRelief',
    'ViewFeeRelief',
    'Fee Relief Table',
    'Create Fee Relief',

    'Read Region',
    'Edit Region',
    'Create Region',
    'Regions',

    'Company Table',
    'ReadCompany',
    'EditCompany',
    'Create Company',

    'Office Groups Table',
    'Create Office Group',
    'View Office Groups',
    'Edit Office Groups',

    'ViewContact',
    'EditContact',
    'CreateContact',

    'Create Report',
    'Edit Report',
    'Reports',

    'Exchange Rates - Euro - Months',
    'Days',
    'Exchange Rates - Euro - Months - Read - Exchange Rates',
    'International Dollar',
    'View International Dollar',
    'Edit International Dollar',
    'CreateInternationalDollarNew',

    'User Permissions Table',
    'User Permissions Create',
    'User Permissions Edit',

    'System Roles Table',
    'System Roles Edit',
    'System Roles Create',
  ],

  // setup any globals needed for all meta routes
  init: (to) => {
    // reset previous values
    MetaDataRoutes.Reset();

    // check whether your are viewing or editing
    MetaDataRoutes.VIEW_OR_EDIT = to.path.split('/').includes('edit') ? 'Edit' : 'View';
  },

  // reset
  Reset: () => {
    // MetaDataRoutes.VIEW_OR_EDIT = null
    MetaDataRoutes.META_STRING = '';
  },

  // Office META data
  Office: {
    required: ['EditOffice', 'ViewOffice', 'Office Dashboard', 'Office Table', 'Create Office'],
    Watch: (to) => {
      switch (to.name) {
        case 'Office Table':
          return MetaDataRoutes.Office.applyMeta(false, to, store.state.dashboardOfficeDashboard);
        case 'Create Office':
          return MetaDataRoutes.Office.applyMeta(false, to, store.state.officeEditor);

        case 'Office Dashboard':
          return watch(
            () => store.state.dashboardOfficeDashboard.office?.ID,
            (ID) => MetaDataRoutes.Office.applyMeta(ID != '' ? false : true, to, store.state.dashboardOfficeDashboard.office)
          );

        case 'EditOffice':
          return watch(
            () => store.state.officeEditor.loadingOffice,
            (loading) => MetaDataRoutes.Office.applyMeta(loading, to, store.state.officeEditor)
          );

        case 'ViewOffice':
          return watch(
            () => store.state.office.currentOffice?.ID,
            (ID) => MetaDataRoutes.Office.applyMeta(ID != '' ? false : true, to, store.state.office.currentOffice)
          );
      }
    },

    applyMeta: (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      switch (to.name) {
        case 'EditOffice':
          MetaDataRoutes.META_STRING += `${MetaDataRoutes.VIEW_OR_EDIT} Office - ${state.office.displayName}, ${state.office.uniqueOfficeID}, ${state.office.city}`;
          break;

        case 'ViewOffice':
          MetaDataRoutes.META_STRING += `Office - ${state.displayName}, ${state.uniqueOfficeID}, ${state.city}`;
          break;

        case 'Office Table':
          MetaDataRoutes.META_STRING += `Offices`;
          break;

        case 'Office Dashboard':
          MetaDataRoutes.META_STRING += `Office Dashboard, ${state.displayName} (${state.uniqueOfficeID}), ${state.street}, ${state.houseNumber}, ${state.additionalAddressInfo}, ${state.zipcode}, ${state.city}, ${state.countryName}`;
          break;

        case 'Create Office':
          MetaDataRoutes.META_STRING += `Create Office`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  // Region META data
  Region: {
    required: ['Read Region', 'Edit Region', 'Create Region', 'Regions'],
    Watch: (to) => {
      console.log('route name ->', to);
      
      switch (to.name) {
        case 'Regions':
          return MetaDataRoutes.Region.applyMeta(false, to, store.state.regionEditor.region);
        case 'Create Region':
          return MetaDataRoutes.Region.applyMeta(false, to, store.state.regionEditor.region);
        case 'Read Region':
          return watch(
            () => store.state.regionEditor.region.ID,
            (ID) => MetaDataRoutes.Region.applyMeta(ID != '' ? false : true, to, store.state.regionEditor.region)
          );

        case 'Edit Region':
          return watch(
            () => store.state.regionEditor.region.ID,
            (ID) => MetaDataRoutes.Region.applyMeta(ID != '' ? false : true, to, store.state.regionEditor.region)
          );
      }
    },

    applyMeta: (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      switch (to.name) {
        case 'Regions':
          MetaDataRoutes.META_STRING += `Regions`;
          break;

        case 'Read Region':
          MetaDataRoutes.META_STRING += `Region - ${state.name}`;
          break;

        case 'Edit Region':
          MetaDataRoutes.META_STRING += `${MetaDataRoutes.VIEW_OR_EDIT} Region - ${state.name}`;
          break;

        case 'Create Region':
          MetaDataRoutes.META_STRING += `Create Region`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  // Company META data
  Company: {
    required: ['Company Table', 'EditCompany', 'ReadCompany', 'Create Company'],
    Watch: (to) => {
      switch (to.name) {
        case 'Create Company':
          return MetaDataRoutes.Company.applyMeta(false, to, store.state.company.currentCompany);
        case 'ReadCompany':
          return watch(
            () => store.state.company.currentCompany.ID,
            (ID) => MetaDataRoutes.Company.applyMeta(ID != '' ? false : true, to, store.state.company.currentCompany)
          );

        case 'EditCompany':
          return watch(
            () => store.state.company.currentCompany.ID,
            (ID) => MetaDataRoutes.Company.applyMeta(ID != '' ? false : true, to, store.state.company.currentCompany)
          );
      }
    },

    applyMeta: (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      switch (to.name) {
        case 'Company Table':
          MetaDataRoutes.META_STRING += `Companies`;
          break;

        case 'EditCompany':
          MetaDataRoutes.META_STRING += `${MetaDataRoutes.VIEW_OR_EDIT} Company - ${state.name}`;
          break;

        case 'ReadCompany':
          MetaDataRoutes.META_STRING += `Company - ${state.name}`;
          break;

        case 'Create Company':
          MetaDataRoutes.META_STRING += `Create Company`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING && MetaDataRoutes.META_STRING.includes("undefined") ? "Companies" : MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  // Person META data
  Person: {
    required: ['EditPerson', 'ViewPerson', 'Persons Table', 'CreatePerson'],
    Watch: (to) => {
      switch (to.name) {
        case 'Persons Table':
          return MetaDataRoutes.Person.applyMeta(false, to, store.state.personEditor);
        case 'CreatePerson':
          return MetaDataRoutes.Person.applyMeta(false, to, store.state.personEditor);

        default:
          return watch(
            () => store.state.personEditor.loadingPerson,
            (loading) => MetaDataRoutes.Person.applyMeta(loading, to, store.state.personEditor)
          );
      }
    },

    applyMeta: (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      switch (to.name) {
        case 'Persons Table':
          MetaDataRoutes.META_STRING += `Persons`;
          break;
        case 'ViewPerson':
          MetaDataRoutes.META_STRING += `Person - ${state.person.firstName} ${state.person.lastName}, ${state.person.uniquePersonID}, ${state.person.primaryTitle}, ${state.person.primaryOfficeName}`;
          break;
        case 'EditPerson':
          MetaDataRoutes.META_STRING += `${MetaDataRoutes.VIEW_OR_EDIT} Person - ${state.person.firstName} ${state.person.lastName}, ${state.person.uniquePersonID}, ${state.person.primaryTitle}, ${state.person.primaryOfficeName}`;
          break;
        case 'CreatePerson':
          MetaDataRoutes.META_STRING += `Create Person`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  // Teams META data
  Teams: {
    required: ['EditTeam', 'ViewTeam', 'Teams Table', 'CreateTeam'],
    Watch: (to) => {
      switch (to.name) {
        case 'Teams Table':
          return MetaDataRoutes.Teams.applyMeta(false, to, store.state.teamEditor);
        case 'CreateTeam':
          return MetaDataRoutes.Teams.applyMeta(false, to, store.state.teamEditor);

        default:
          return watch(
            () => store.state.teamEditor.loading,
            (loading) => MetaDataRoutes.Teams.applyMeta(loading, to, store.state.teamEditor)
          );
      }
    },

    applyMeta: (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      switch (to.name) {
        case 'Teams Table':
          MetaDataRoutes.META_STRING += `Teams`;
          break;

        case 'CreateTeam':
          MetaDataRoutes.META_STRING += `Create Team`;
          break;

        // case 'EditTeam':
        //   MetaDataRoutes.META_STRING += `Edit Team`;
        //   break;

        default:
          /* eslint-disable */
          const teamLeader = state.persons.list.filter((person) => person.ID == state.teamLeader.teamLeaderID)[0];
          const teamLeaderName = teamLeader ? teamLeader.personName : 'N/A';
        
          MetaDataRoutes.META_STRING += `${MetaDataRoutes.VIEW_OR_EDIT} Team - ${state.team.name}, ${state.office.displayName}, ${teamLeaderName}`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  // Fee Relief META data
  Fee_Relief: {
    required: ['EditFeeRelief', 'ViewFeeRelief', 'Fee Relief Table', 'Create Fee Relief'],
    Watch: (to) => {
      switch (to.name) {
        case 'Fee Relief Table':
          return MetaDataRoutes.Fee_Relief.applyMeta(false, to, store.state.feeReliefEditor);

        default:
          return watch(
            () => store.state.feeReliefEditor.loading,
            (loading) => MetaDataRoutes.Fee_Relief.applyMeta(loading, to, store.state.feeReliefEditor)
          );
      }
    },

    applyMeta: (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      switch (to.name) {
        case 'Fee Relief Table':
          MetaDataRoutes.META_STRING += `Annual Dues Fee Relief`;
          break;
        case 'ViewFeeRelief':
          MetaDataRoutes.META_STRING += `Fee Relief - ${state.feeRelief.campaignName}`;
          break;
        case 'EditFeeRelief':
          MetaDataRoutes.META_STRING += `${MetaDataRoutes.VIEW_OR_EDIT} Fee Relief - ${state.feeRelief.campaignName}`;
          break;
        case 'Create Fee Relief':
          MetaDataRoutes.META_STRING += `Create Fee Relief`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  // Annual Dues META data
  Annual_Dues: {
    required: [
      'AnnualDuesReportsCurrent',
      'AnnualDuesReportsMonthlyOverview',
      'AnnualDuesReportsFinancialOverview',
      'AnnualDuesReportsOverview',
    ],
    Watch: (to) => {
      switch (to.name) {
        case 'AnnualDuesReportsOverview':
          return MetaDataRoutes.Annual_Dues.applyMeta(false, to, {});

        case 'AnnualDuesReportsCurrent':
          return watch(
            () => store.state.annualDuesReportsCurrent.loading,
            (loading) => MetaDataRoutes.Annual_Dues.applyMeta(loading, to, store.state.annualDuesReportsCurrent)
          );

        case 'AnnualDuesReportsMonthlyOverview':
          return watch(
            () => store.state.annualDuesReportsMonthlyOverview.loading,
            (loading) => MetaDataRoutes.Annual_Dues.applyMeta(loading, to, store.state.annualDuesReportsMonthlyOverview)
          );

        case 'AnnualDuesReportsFinancialOverview':
          return watch(
            () => store.state.annualDuesReportsFinanceOverview.loading,
            (loading) => MetaDataRoutes.Annual_Dues.applyMeta(loading, to, store.state.annualDuesReportsFinanceOverview)
          );
      }
    },

    applyMeta: (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      switch (to.name) {
        case 'AnnualDuesReportsCurrent':
          MetaDataRoutes.META_STRING += `Edit Annual Dues, ${state.region.corporateName}, ${
            to.query.reportYear ? `${MetaDataRoutes.MONTHS[Number(to.query.reportMonth) - 1]} ${to.query.reportYear}` : ''
          }`;
          break;

        case 'AnnualDuesReportsMonthlyOverview':
          MetaDataRoutes.META_STRING += `Annual Dues, ${state.region.corporateName}`;
          break;

        case 'AnnualDuesReportsFinancialOverview':
          MetaDataRoutes.META_STRING += `Annual Dues Finance Overview, ${state.region.corporateName}`;
          break;

        case 'AnnualDuesReportsOverview':
          MetaDataRoutes.META_STRING += `Annual Dues`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  // Monthly Reporting META data
  Monthly_Reporting: {
    required: [
      'MonthlyReporting-Regions-Read-Months-Read-Offices',
      'MonthlyReportingRegionsReadMonths',
      'MonthlyReporting-Regions-Read-Months-Read-Offices-Edit',
    ],
    Watch: (to) => MetaDataRoutes.Monthly_Reporting.applyMeta(false, to, store.state.office.currentOffice),

    applyMeta: async (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      const urls = [
        `/monthly-reporting/region/monthly-overview/${to.params.regionID}/${to.params.region_name}`,
        `/monthly-reporting/region/${to.params.regionID}/${to.params.month}/${to.params.year}/${to.params.region_name}`,
      ];
      const OfficeOrMonth =
        decodeURIComponent(to.path) == urls[0] ? 'Reporting Month Overview' : 'Reporting Office Overview';
      const reportMonth = to.params.year ? `${MetaDataRoutes.MONTHS[Number(to.params.month) - 1]} ${to.params.year}` : false;

      // gets office info for id from the params
      const getOfficeData = async () => {
        if (to.name == 'MonthlyReporting-Regions-Read-Months-Read-Offices-Edit') {
          const res = await store.dispatch('officeEditor/getSingleOffice', to.params.office_id);
          if (res.data && res.data.result) return res.data.result;
          else return res;
        }
      };

      const officeData = await getOfficeData();
      switch (to.name) {
        case 'MonthlyReporting-Regions-Read-Months-Read-Offices-Edit':
          MetaDataRoutes.META_STRING += `Edit Reporting for Office, ${officeData.displayName}, ${reportMonth}`;
          break;

        case 'MonthlyReporting-Regions-Read-Months-Read-Offices':
          MetaDataRoutes.META_STRING += `${OfficeOrMonth}, ${decodeURIComponent(to.params.region_name)}, ${
            reportMonth ? reportMonth : ''
          }`;
          break;

        case 'MonthlyReportingRegionsReadMonths':
          MetaDataRoutes.META_STRING += `${OfficeOrMonth}, ${
            reportMonth ? reportMonth : decodeURIComponent(to.params.region_name)
          }`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING}, RE/MAX Europe Data Hub`;
    },
  },

  Office_Groups: {
    required: ['Office Groups Table', 'Create Office Group', 'View Office Groups', 'Edit Office Groups'],
    Watch: (to) => {
    //   debugger;
      switch (to.name) {
        case 'Office Groups Table':
          return MetaDataRoutes.Office_Groups.applyMeta(false, to, {});
        case 'Create Office Group':
          return MetaDataRoutes.Office_Groups.applyMeta(false, to, {});

        case 'View Office Groups':
          return watch(
            () => store.state.officeGroupEditor.loading,
            (loading) => MetaDataRoutes.Office_Groups.applyMeta(loading, to, store.state.officeGroupEditor)
          );

        case 'Edit Office Groups':
          return watch(
            () => store.state.officeGroupEditor.loading,
            (loading) => MetaDataRoutes.Office_Groups.applyMeta(loading, to, store.state.officeGroupEditor)
          );
      }
    },

    applyMeta: (loading, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      switch (to.name) {
        case 'Office Groups Table':
          MetaDataRoutes.META_STRING += `Office Groups`;
          break;

        case 'Create Office Group':
          MetaDataRoutes.META_STRING += `Create Office Group`;
          break;

        default:
          MetaDataRoutes.META_STRING += `${MetaDataRoutes.VIEW_OR_EDIT} Office Group - ${state.group.name} ${state.group.regionName}`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  Contacts: {
    required: ['ViewContact', 'EditContact', 'CreateContact'],
    Watch: (to) => {
      switch (to.name) {
        case 'CreateContact':
          return MetaDataRoutes.Contacts.applyMeta(false, to, {});
        default:
          return watch(
            () => store.state.contactEditor.loading,
            (loading) => MetaDataRoutes.Contacts.applyMeta(loading, to, store.state.contactEditor)
          );
      }
    },

    applyMeta: (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      switch (to.name) {
        case 'CreateContact':
          MetaDataRoutes.META_STRING += `Create Contact`;
          break;
        default:
          MetaDataRoutes.META_STRING += `${MetaDataRoutes.VIEW_OR_EDIT} Contact, ${state.person.firstName}`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  Reports: {
    required: ['Edit Report', 'Reports', 'Create Report'],
    Watch: (to) => {
      switch (to.name) {
        case 'Reports':
          return MetaDataRoutes.Reports.applyMeta(false, to, {});
        case 'Edit Report':
          return watch(
            () => store.state.reports.reportData?.title,
            () => MetaDataRoutes.Reports.applyMeta(false, to, store.state.reports.reportData),
          );
        case 'Create Report':
          return watch(
            () => store.state.reports.reportData?.title,
            () => MetaDataRoutes.Reports.applyMeta(false, to, store.state.reports.reportData),
          );
      }
    },

    applyMeta: (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      // get selected report from state
      const reportTitle = to.name != 'Reports' ? state?.title || to.params.name : '';

      switch (to.name) {
        case 'Reports':
          MetaDataRoutes.META_STRING += `Reports`;
          break;

        case 'Edit Report':
          MetaDataRoutes.META_STRING += `${reportTitle} - ${MetaDataRoutes.VIEW_OR_EDIT} Report`;
          break;

        case 'Create Report':
          MetaDataRoutes.META_STRING += `${reportTitle} - Create Report`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  Exchange_Rates: {
    required: [
      'Exchange Rates - Euro - Months',
      'Days',
      'International Dollar',
      'View International Dollar',
      'Edit International Dollar',
      'Exchange Rates - Euro - Months - Read - Exchange Rates',
      'CreateInternationalDollarNew',
    ],
    Watch: (to) => {
      switch (to.name) {
        case 'Exchange Rates - Euro - Months':
          return MetaDataRoutes.Exchange_Rates.applyMeta(false, to, {});
        case 'Days':
          return MetaDataRoutes.Exchange_Rates.applyMeta(false, to, {});
        case 'Exchange Rates - Euro - Months - Read - Exchange Rates':
          return MetaDataRoutes.Exchange_Rates.applyMeta(false, to, {});
        case 'International Dollar':
          return MetaDataRoutes.Exchange_Rates.applyMeta(false, to, {});
        case 'View International Dollar':
          return MetaDataRoutes.Exchange_Rates.applyMeta(false, to, {});
        case 'Edit International Dollar':
          return MetaDataRoutes.Exchange_Rates.applyMeta(false, to, {});
        case 'CreateInternationalDollarNew':
          return MetaDataRoutes.Exchange_Rates.applyMeta(false, to, {});
      }
    },

    applyMeta: (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      switch (to.name) {
        case 'International Dollar':
          MetaDataRoutes.META_STRING += `International Dollar Rates`;
          break;
        case 'View International Dollar':
          MetaDataRoutes.META_STRING += `International Dollar Rates for ${to.params.countryName}`;
          break;
        case 'Edit International Dollar':
          MetaDataRoutes.META_STRING += `International Dollar Rates for ${to.params.countryName}`;
          break;
        case 'CreateInternationalDollarNew':
          MetaDataRoutes.META_STRING += `Create International Dollar Rates 2024`;
          break;
        case 'Exchange Rates - Euro - Months':
          MetaDataRoutes.META_STRING += `Currency Exchange Rate`;
          break;
        case 'Days':
          MetaDataRoutes.META_STRING += `Currency Exchange Rate - ${MetaDataRoutes.MONTHS[Number(to.params.month) - 1]} ${
            to.params.year
          }`;
          break;
        case 'Exchange Rates - Euro - Months - Read - Exchange Rates':
          MetaDataRoutes.META_STRING += `Currency Exchange Rate - ${MetaDataRoutes.MONTHS[Number(to.params.month) - 1]} ${
            to.params.year
          }`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  System_Access: {
    required: ['User Permissions Table', 'User Permissions Create', 'User Permissions Edit'],
    Watch: (to) => {
    //   debugger;
      switch (to.name) {
        case 'User Permissions Edit':
          return MetaDataRoutes.System_Access.applyMeta(false, to, {});
        default:
          return MetaDataRoutes.System_Access.applyMeta(false, to, {});
      }
    },

    applyMeta: async (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      // gets person info for meta data
      let Person = { personFullDisplayName: '' };
      const getPersonData = async () =>
        await store.dispatch('systemAccess/getSystemAccessByID', {
          ID: to.params.id,
        });

      if (to.name == 'User Permissions Edit') Person = await getPersonData();
      switch (to.name) {
        case 'User Permissions Table':
          MetaDataRoutes.META_STRING += `User Permissions`;
          break;
        case 'User Permissions Create':
          MetaDataRoutes.META_STRING += `Create User Permission`;
          break;
        case 'User Permissions Edit':
          MetaDataRoutes.META_STRING += `Edit User Permission - ${Person.personFullDisplayName}`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  Roles: {
    required: ['System Roles Table', 'System Roles Edit', 'System Roles Create'],
    Watch: (to) => {
      switch (to.name) {
        default:
          return MetaDataRoutes.Roles.applyMeta(false, to, {});
      }
    },

    applyMeta: (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      switch (to.name) {
        case 'System Roles Table':
          MetaDataRoutes.META_STRING += `Access Role`;
          break;
        case 'System Roles Edit':
          MetaDataRoutes.META_STRING += `Edit Access Role`;
          break;
        case 'System Roles Create':
          MetaDataRoutes.META_STRING += `Create Access Role`;
          break;
      }

      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },

  default_meta_route: {
    required: [],
    Watch: (to) => {
      switch (to.name) {
        default:
          return MetaDataRoutes.default_meta_route.applyMeta(false, to, {});
      }
    },

    applyMeta: (loading: boolean, to, state: any) => {
      // wait for load to finish
      if (loading) return;

      // reset previous values
      MetaDataRoutes.Reset();

      let title = to.name;
      if (to.params?.region_name) {
          title += `, ${to.params.region_name.replace('REMAX', 'RE/MAX')}`;
      }
      if (to.params?.month || to.params?.year) {
          title += `,`;
          if (to.params.month) {
              title += ` ${MetaDataRoutes.MONTHS[Number(to.params.month) - 1]}`;
          }
          if (to.params.year) {
              title += ` ${to.params.year}`;
          }
      }

      MetaDataRoutes.META_STRING += title;
      // apply the meta data
      document.title = `${MetaDataRoutes.META_STRING} - RE/MAX Europe Data Hub`;
    },
  },
};
